@import url("../node_modules/devextreme/dist/css/dx.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css");
@import url("../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css");

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#app-menu-selected {
  background: #c92279;
  color: #ffffff;
  padding-left: 12px;
  display: flex;
  gap: 8px;
}

.white-text .dx-button-content,
.white-text .dx-icon {
  color: #fff !important;
}

.dx-datagrid-header-panel {
  padding: 8px;
}

.dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
}

.dx-treeview-item {
  cursor: pointer;
  padding: 5px 6px;
  min-height: 32px;
  border-radius: 8px 16px 16px 8px;
  margin-top: 4px;
  transition: padding 0.2s;
}

.document-viewer {
  flex-grow: 1;
}

.document-viewer .dx-field-item-content {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 80vh;
}

.document-viewer iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Invoice Editor */
.invoice-editor {
  font-family: Arial, Helvetica, sans-serif !important;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
}

.invoice-editor > section {
  max-height: calc(100vh - 64px - 52px);
}

.invoice-editor h1 {
  margin: 0;
  font-size: 18px;
}

.invoice-editor button {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 4px;
}

.invoice-editor button[data-active="true"] {
  background: #ccc;
}

.invoice-editor table {
  border-spacing: 0;
  width: 100%;
}

.invoice-editor td {
  margin: 0;
  padding: 6px 8px;
  border-bottom: 1px solid #888;
  vertical-align: top;
}

.invoice-editor tr:last-child td {
  border: 0;
}

.invoice-editor th {
  border-bottom: 1px solid #333;
}

.invoice-editor .row-alternation tr:nth-child(even) {
  background: #ddd;
}

.invoice-editor .row-alternation tr:last-child td {
  border-bottom: 0;
}

.invoice-editor hr {
  width: 100%;
  border: 0;
  outline: 1px solid #ccc;
}

.invoice-editor p.editor-preview-item {
  word-break: break-all;
  margin: 0;
}

.invoice-editor .editor-preview-item {
  position: relative;
}

.invoice-editor .editor-preview-item.box {
  padding: 8px;
}

.invoice-editor [data-selected="true"] {
  outline: 2px solid rgb(64, 155, 253) !important;
}
.invoice-editor .editor-preview-item.mouse-hover {
  outline: 1px solid rgb(64, 155, 253);
}
.invoice-editor .drop-area.drag-hover {
  outline: 2px solid rgb(152, 0, 0);
}

.dx-toolbar .dx-toolbar-label {
  max-width: none !important;
}

.dx-toast-error{
  visibility: hidden !important;
}